import React from 'react';

const Cart = () => {
    return (
        <div>
            I am Cart
        </div>
    );
};

export default Cart;