import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AuthProvider from './contexts/AuthProvider/AuthProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
    <App />
    </AuthProvider>
  </React.StrictMode>
);

export const navLinks = [
  {id: "home",
title: "Home"}, 
//   {id: "Services",
// title: "Services"}, 
  {id: "Products",
title: "Products"},
{id: "Sign Up",
title: "Sign Up"},
{id: "Login",
title: "Login"} ,
// {id: "About",
// title: "About"}  
];

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
